<template>
  <div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  created() {
    console.log(wx.miniProgram)
    let verify_token = this.$route.query.verify_token
    wx.miniProgram.reLaunch({
      url: `/pages/home/index?verify_token=${verify_token}`
    })
  },
}
</script>

<style  scoped></style>
